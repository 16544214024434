import { getApi, postApi, getApiWithoutBack, deleteApi, patchApi } from "@/store/API/api";
import { Action } from "@/store/actionType";
import { SubsidyReport } from "@/store/models/DemandAndSupply";

export default new (class CropAPI {
  public async getAllSeedType(): Promise<any> {
    const url = `${Action.SeedType}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getMyAllocation(query: string = ""): Promise<any> {
    const url = `${Action.MyAllocation}`;
    const res = await getApi(url, query);
    return res as any;
  }

  public async getVerifyAllocation(query: string = ""): Promise<any> {
    const url = `${Action.VerifyAllocation}`;
    const res = await getApi(url, query);
    return res as any;
  }

  public async patchMyAllocationVerify(params: any, id: any): Promise<any> {
    const url = `${Action.VerifyAllocation}/${id}`;
    const res = await patchApi(url, params);

    return res as any;
  }

  public async patchMyAllocation(params: any, id: any): Promise<any> {
    const url = `${Action.MyAllocation}/${id}`;
    const res = await patchApi(url, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return res as any;
  }

  public async getAllDemands(query: string = ""): Promise<any> {
    const url = `${Action.Demand}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAllDemandCluster(query: string = ""): Promise<any> {
    const url = `${Action.Cluster}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAdminDemands(query: string = ""): Promise<any> {
    const url = `${Action.AdminDemand}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getDemandSupplyByClusterId(clusterId: number, query: string = ""): Promise<any> {
    const url = `${Action.Cluster}/${clusterId}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getSupplyById(id: number): Promise<any> {
    const url = `${Action.AdminSupply}/${id}`;
    const response = await getApi(url);
    return response as any;
  }

  public async postDemand(demand: any): Promise<any> {
    const url = `${Action.Demand}`;
    const response = await postApi(url, demand);
    return response as any;
  }

  public async deleteDemand(id: any): Promise<any> {
    const url = `${Action.Demand}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async confirmDemands(ids: number[]): Promise<any> {
    const url = `${Action.DemandConfirm}`;
    const response = await postApi(url, ids);
    return response as any;
  }

  public async getAdminSupplies(query: string = ""): Promise<any> {
    const url = `${Action.AdminSupply}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getApproveAllocation(query: string = ""): Promise<any> {
    const url = `${Action.ApproveAllocation}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async approveAllocationById(id: number, lang: string): Promise<any> {
    const url = `${Action.ApproveAllocation}/${id}/approve_subsidy/?lang=${lang}`;
    const response = await postApi(url, {});
    return response as any;
  }

  public async rejectAllocationById(id: number, message: string, lang: string): Promise<any> {
    const url = `${Action.ApproveAllocation}/${id}/reject_subsidy/?lang=${lang}`;
    const response = await postApi(url, { message: message });
    return response as any;
  }

  public async getAllSupplies(query: string = ""): Promise<any> {
    const url = `${Action.Supply}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAllSupplyCluster(query: string = ""): Promise<any> {
    const url = `${Action.Cluster}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async postSupply(supply: any): Promise<any> {
    const url = `${Action.Supply}`;
    const response = await postApi(url, supply, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }

  public async deleteSupply(id: any): Promise<any> {
    const url = `${Action.Supply}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async confirmSupplies(ids: number[]): Promise<any> {
    const url = `${Action.SupplyConfirm}`;
    const response = await postApi(url, ids);
    return response as any;
  }

  public async getPreferences(): Promise<any> {
    const url = `${Action.Preferences}`;
    const response = await getApi(url);
    return response as any;
  }

  public async postPreference(preferences: any): Promise<any> {
    const url = `${Action.Preferences}`;
    const response = await postApi(url, preferences);
    return response as any;
  }

  public async delete(id: any): Promise<any> {
    const url = `${Action.Preferences}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async getFyWiseSubsidyCrops(query: string = ""): Promise<any> {
    const url = `${Action.FyWiseSubsidyCropList}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getFyWiseSubsidy(query: string = ""): Promise<any> {
    const url = `${Action.FyWiseSubsidy}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getSubsidyCrops(query: string = ""): Promise<any> {
    const url = `${Action.SubsidyCropList}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getSubsidy(query: string = ""): Promise<any> {
    const url = `${Action.Subsidy}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getSubsidyBatch(cropId: number, query: string = ""): Promise<any> {
    const url = `${Action.CurrentSubsidyBatch}/${cropId}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async submitSubsidyBatch(id: number): Promise<any> {
    const url = `${Action.SubsidyBatch}/${id}/submit`;
    const response = await postApi(url, {});
    return response as any;
  }

  public async getSubsidyDocument(subsidyBatchId: number, query: string = ""): Promise<any> {
    const url = `${Action.CurrentSubsidyDocument}/${subsidyBatchId}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async postSubsidy(subsidy: any): Promise<any> {
    const url = `${Action.Subsidy}`;
    const response = await postApi(url, subsidy);
    return response as any;
  }

  public async getSubsidySchedule(query: string = ""): Promise<any> {
    const url = `${Action.SubsidySchedule}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async updateSubsidySchedule(subsidySchedule: any): Promise<any> {
    const url = `${Action.SubsidySchedule}`;
    const response = await postApi(url, subsidySchedule);
    return response as any;
  }

  public async updateSubsidyDocuments(id: number, subsidyDocuments: any): Promise<any> {
    const url = `${Action.UploadDocument}/${id}`;
    const response = await patchApi(url, subsidyDocuments, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }

  public async patchSubsidyDocument(id: number, subsidyDocument: any): Promise<any> {
    const url = `${Action.UploadDocument}/${id}`;
    const response = await patchApi(url, subsidyDocument, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }

  public async removeSubsidyDocument(id: number, subsidyDocument: any): Promise<any> {
    const url = `${Action.UploadDocument}/${id}`;
    const response = await patchApi(url, subsidyDocument, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }

  public async deleteSubsidy(id: any): Promise<any> {
    const url = `${Action.Subsidy}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async deletePreference(id: any): Promise<any> {
    const url = `${Action.Preferences}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async getSupplier(query = ""): Promise<any> {
    const url = `${Action.Supplier}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getCurrentMarketStat(): Promise<any> {
    const url = `${Action.CurrentMarketStat}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getSupplierStat(): Promise<any> {
    const url = `${Action.SupplierStat}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getSupplierInfo(query: string = ""): Promise<any> {
    const url = `${Action.SupplierInfo}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getSupplierBySlug(slug: string): Promise<any> {
    const url = `${Action.SupplierInfo}/${slug}`;
    const response = await getApi(url);
    return response as any;
  }

  public async getNotices(): Promise<any> {
    const url = `${Action.Notices}`;
    const response = await getApi(url);
    return response as any;
  }

  public async distributionsSold(distributions: any[]): Promise<any> {
    const url = `${Action.Distribution}`;
    const response = await postApi(url, distributions);
    return response as any;
  }

  public async saveDistributionSoldBill(params: any): Promise<any> {
    const url = `${Action.DistributionSoldBill}`;
    const response = await postApi(url, params, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }

  public async getBalanceSheetReport(query: string): Promise<any> {
    const url = `${Action.BalanceSheetReport}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async unsoldAllocationReport(query: string): Promise<any> {
    const url = `${Action.UnsoldAllocationReport}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async unsoldAllocationReportDetail(params: any): Promise<any> {
    const url = `${Action.UnsoldAllocationReportDetail}`;
    const response = await getApi(url, params);
    return response as any;
  }

  public async unsoldAllocationDemandDetail(params: any): Promise<any> {
    const url = `${Action.UnsoldAllocationDemandDetail}`;
    const response = await getApi(url, params);
    return response as any;
  }

  public async getAdminPurchases(query: string = ""): Promise<any> {
    const url = `${Action.AdminPurchase}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAllPurchases(query: string = ""): Promise<any> {
    const url = `${Action.Purchase}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAllPurchaseCluster(query: string = ""): Promise<any> {
    const url = `${Action.Cluster}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async postPurchase(purchase: any): Promise<any> {
    const url = `${Action.Purchase}`;
    const response = await postApi(url, purchase, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }

  public async deletePurchase(id: any): Promise<any> {
    const url = `${Action.Purchase}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async confirmPurchases(ids: number[]): Promise<any> {
    const url = `${Action.PurchaseConfirm}`;
    const response = await postApi(url, ids);
    return response as any;
  }

  public async getAdminSales(query: string = ""): Promise<any> {
    const url = `${Action.AdminSale}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAllSales(query: string = ""): Promise<any> {
    const url = `${Action.Sale}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAllSaleCluster(query: string = ""): Promise<any> {
    const url = `${Action.Cluster}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async postSale(sale: any): Promise<any> {
    const url = `${Action.Sale}`;
    const response = await postApi(url, sale, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response as any;
  }

  public async deleteSale(id: any): Promise<any> {
    const url = `${Action.Sale}`;
    const response = await deleteApi(url, id);
    return response as any;
  }

  public async confirmSales(ids: number[]): Promise<any> {
    const url = `${Action.SaleConfirm}`;
    const response = await postApi(url, ids);
    return response as any;
  }

  public async getSubsidyReport(data: SubsidyReport): Promise<any> {
    const url = `${Action.SubsidyReport}`;
    const response = await postApi(url, data);
    return response as any;
  }

  public async getDemandReport(data: SubsidyReport): Promise<any> {
    const url = `${Action.DemandReport}`;
    const response = await postApi(url, data);
    return response as any;
  }

  public async getSupplyReport(data: SubsidyReport): Promise<any> {
    const url = `${Action.SupplyReport}`;
    const response = await postApi(url, data);
    return response as any;
  }

  public async getMunicipalityWiseAllocationReport(data: {
    fiscal_year: number;
    crop_type: number;
    seed_type: number;
    demand_company_user: number;
  }): Promise<any> {
    const url = `${Action.MunicipalityWiseAllocationReport}`;
    let query = `?fiscal_year=${data.fiscal_year}&crop_type=${data.crop_type}&demand_company_user=${data.demand_company_user}&seed_type=${data.seed_type}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getSubsidyNotice(query = ""): Promise<any> {
    const url = `${Action.SubsidyNotice}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async uploadSourceSeedSaleBill(data: FormData, id: number): Promise<any> {
    const url = `${Action.UploadSourceSeedSaleBill}`.replace("<supplyId>", `${id}`);
    const response = await patchApi(url, data);
    return response as any;
  }

  public async approveSalesDetail(supplyId: number, data: any): Promise<any> {
    const url = `${Action.ApproveSalesDetail}`.replace("<supplyId>", `${supplyId}`);
    const response = await postApi(url, data);
    return response as any;
  }

  public async rejectSalesDetail(supplyId: number, data: any): Promise<any> {
    const url = `${Action.RejectSalesDetail}`.replace("<supplyId>", `${supplyId}`);
    const response = await postApi(url, data);
    return response as any;
  }

  public async updateAdminSupply(id: number, supply: any): Promise<any> {
    const url = `${Action.AdminSupply}/${id}`;
    const response = await patchApi(url, supply);
    return response as any;
  }

  public async updateAdminDemand(id: number, demand: any): Promise<any> {
    const url = `${Action.AdminDemand}/${id}`;
    const response = await patchApi(url, demand);
    return response as any;
  }

  public async getAdminSuppliesTotalQuantity(query: string = ""): Promise<any> {
    const url = `${Action.AdminTotalSupplyQuantityAPI}`;
    const response = await getApi(url, query);
    return response as any;
  }

  public async getAdminDemandsTotalQuantity(query: string = ""): Promise<any> {
    const url = `${Action.AdminTotalDemandQuantityAPI}`;
    const response = await getApi(url, query);
    return response as any;
  }
})();
